.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin-right: 10px;
  }
}

.appointmentContainer {
  display: flex;
  justify-content: space-between;
  text-align: center;

  .dayContainer {
    background-color: #6b818c;
    color: white;
    padding: 15px 25px;
    border-radius: 8px;
    width: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-size: 24px;
      font-weight: bold;
    }

    @media only screen and (max-width: 1024px) {
      h4 {
        font-size: 14px;
        font-weight: bold;
      }
      p {
        font-size: 10px;
      }
    }
  }
}

.timeBox {
  background-color: #7fc6a4;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 2px;
  width: 65px;
  margin-right: 4px;
  margin-top: 4px;

  &:hover {
    background-color: #f3f3f3 !important;
    color: black !important;
    border: 1px solid black;
    cursor: pointer;
  }
}

.choosenTimeBox {
  text-align: center;
  padding: 10px;
  border-radius: 2px;
  width: 65px;
  margin-right: 4px;
  margin-top: 4px;

  background-color: #f3f3f3;
  color: black;
  border: 1px solid silver;
}
