.sidebar {
  .logo {
    box-shadow: none;
  }

  .sidebar-wrapper {
    height: calc(100% - 120px);
    box-shadow: none;
  }

  &[data-image]:after {
    opacity: 0.9;
  }
}

.full-page[data-color='blue']:after,
.sidebar[data-color='blue']:after {
  background: linear-gradient(
    to bottom,
    #1814ab 0%,
    rgba(36, 171, 209, 0.9) 100%
  );
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 15px 0;
}

input[type='radio'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
  margin-left: 5px;
}
input[type='radio']:before {
  position: relative;
  font-family: 'FontAwesome';
  content: '';
  font-size: 18px;
  color: #a9a9a9;
  margin-right: 0.5rem;
  top: 0.3rem;
}
input[type='radio']:checked:before {
  content: '\f046';
  color: #1474cc;
  margin-right: 0.265rem;
}
