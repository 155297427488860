.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.containerBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 15px;
}

.containerBetweenNoMargin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px;
}

.containerLeftAlign {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}
