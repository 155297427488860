.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin-right: 10px;
  }
}

.appointmentContainer {
  display: flex;
  justify-content: space-between;
  text-align: center;
  // overflow-x: scroll;
  // width: 1400px;

  .dayContainer {
    background-color: #6b818c;
    color: white;
    padding: 15px 25px;
    border-radius: 8px;
    width: 18%;
  
    h4 {
      font-size: 24px;
      font-weight: bold;
    }
  
    div {
      background-color: #e7ebc5;
      color: black;
      border-radius: 8px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 10px;
  
      p {
        font-size: 18px;
      }
    }

    @media only screen and (max-width: 1024px)  {
      h4 {
        font-size: 14px;
        font-weight: bold;
      }
      p {
        font-size: 10px;
      }
    }
  }
}

.timeBox {
  background-color: #16cc74;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 2px;
  width: 65px;
  margin-right: 4px;
  margin-top: 4px;

  &:hover {
    background-color: #f3f3f3 !important;
    color: black !important;
    cursor: pointer;
  }
}