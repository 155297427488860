@import '~styles/variables.scss';

.navbarWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 64px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;

  .navbarName {
    height: 21px;
    font-weight: 300;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $blackReal;
    margin: 0 10px;
  }

  .navbarContact {
    font-style: italic;
    font-size: 12px;
    height: 25px;
    font-weight: 200;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .navbarLogout {
    height: 21px;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $lightGray;
    margin: 0 10px;
    cursor: pointer;
  }

  i {
    width: 40px;
    height: 41px;
    font-size: 42px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $lightGray;
    margin: 0 10px;
  }

  .rightControl {
    display: flex;
    align-items: center;
    cursor: pointer;
    i {
      height: auto;
      font-size: 20px;
    }
  }

  .openMenu {
    i {
      font-size: 40px;
    }
  }

  @media screen and (min-width: 992px) {
    .rightControl {
      display: none;
    }
  }
}
@media screen and (max-width: 991px) {
  .navbarWrapper {
    padding-right: 0;
  }
}
