$spaceamounts: (4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-break {
  word-break:break-all;
}

.overflow-hidden {
  overflow: hidden;
}
