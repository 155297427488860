.backgroundImageGradient {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    background-image: linear-gradient(to bottom, #130cb7, #14dee1 133%);
}
