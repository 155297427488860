.form-group {
  label {
    font-weight: 400;
    color: $black;
  }
}

.field-required {
  &::after {
    content: "*";
    color: #fa1825;
    margin-left: 3px;
  }
}

.textEditorWrap {
  border: solid 1px #e3e3e3;
  margin-bottom: 15px;
}

.textEditorToolbar {
}

.textEditorInput {
  padding-left: 15px;
  padding-right: 15px;
}
