@import '~styles/variables.scss';

@media (max-width: 768px) {
    .breadCrumbs {
    margin-bottom: 4rem !important;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 10px;
    height: auto !important;

} 
}