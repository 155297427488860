.thumbnailCellWrap {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}