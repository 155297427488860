.contentWrapper {
  width: 60.59%;
  margin: 0 auto;
  text-align: center;
  margin-top: 200px;
}

.welcomeImage {
  width: 100%;
  margin-bottom: 5rem;
}

.email {
  font-size: 3rem;
  color: #454545;
  margin-bottom: 3rem;
}

@media (max-width: 767px) {
  .contentWrapper {
      width: 80vw;
      margin-top: 10vh;
  }
}