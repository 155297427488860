.sidebarTitle {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 260px;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  height: 40px;
}

#scrollBarMenu::-webkit-scrollbar-track
{
  box-shadow: inset;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: rgba(102, 94, 94, 0.87);
}

#scrollBarMenu::-webkit-scrollbar
{
  width: 8px;
}

#scrollBarMenu::-webkit-scrollbar-thumb
{
  box-shadow: none;
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #C9C9C9;
}

:global(.simplebar-placeholder) {
  display: none;
}