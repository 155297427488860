.not-found {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: 'Roboto-Regular';
    height: 57px;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #454545;
  }

  @media (max-width: 440px) {
    img {
      width: 320px;
    }
    h1 {
      height: 48px;
      font-size: 38px;
    }
  }

  @media (max-width: 340px) {
    img {
      width: 280px;
    }
    h1 {
      height: 36px;
      font-size: 32px;
    }
  }
}
